<template>
  <div v-if="question">
    <sqr-input-email
      name="email"
      autocomplete="email"
      :label="question.name"
      :placeholder="question.placeholder"
      :required="question.required"
      :value="answer"
      @change="$emit('change', $event)"
      :disabled="disabled"
      :v="v"
    />
  </div>
</template>

<script>
import SqrInputEmail from '@/sqrd-ui/SqrInputEmail';
export default {
  name: 'EmailView',
  components: { SqrInputEmail },
  props: {
    question: { type: Object },
    answer: { type: String },
    disabled: { type: Boolean },
    v: { type: Object },
  },
};
</script>
